<template>
    <el-col
        class="comps-group-item"
        :ref="conf.field"
        :sm="24"
        :md="24"
    >
        <template v-for="(item, index) in drawList">
            <el-col
                :key="index"
                v-show="item.show"
                class="comps-group-sub-item"
                :ref="item.field"
                :sm="24"
                :md="item.span || 24"
            >
                <UploadImg v-if="item.type == 'upload'" :conf="item" />
                <UploadImgMulti v-else-if="item.type == 'miupload'" :conf="item" />
                <UploadFile v-else-if="item.type == 'fileload'" :conf="item" />
                <AddressComp v-else-if="item.type == 'address'" :conf="item" />
                <CompItem v-else :conf="item" @chooseValue="handleChooseValue" @explainLink="handlerExplainLink" />
            </el-col>
        </template>
    </el-col>
</template>

<script>
import CompItem from "./CompItem.vue";
import UploadImg from "./UploadImg.vue"; // 单图片上传
import UploadImgMulti from "./UploadImgMulti.vue"; // 多图片上传
import UploadFile from "./UploadFile.vue"; // 附件上传
import AddressComp from "./AddressComp.vue"; // 地址选择

export default {
    name: 'CompsGroup',
    components: {
        CompItem,
        UploadImg,
        UploadImgMulti,
        UploadFile,
        AddressComp
    },
    props: [
        'conf',
        'drawList'
    ],
    data() {
        return {}
    },
    filters: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    beforeDestroy() {},
    methods: {
        handleChooseValue(comp, val) {
            this.$emit('chooseValue', comp, val, this.drawList)
        },
        handlerExplainLink(comp) {
            this.$emit('explainLink', comp)
        }
    }
}
</script>

<style lang="scss" scoped>
.comps-group-item {
    padding: 22px 22px 0;
    border: 1px dashed #c7c7cc;
    border-radius: 4px;
    margin-top: 11px;
}

.comps-group-sub-item {
    // box-sizing: border-box;
    // padding: 16px 16px;
}
</style>

<template>
    <div class="stu-info-fill-main">
        <div v-if="templateInfo.updateType == '1'">
            <form-main-title
                style="margin:0 auto;"
                :title="templateInfo.archivesName"
            ></form-main-title>
            <stu-info-table
                :mode="mode"
                :school-id="schoolId"
                :stu-basic-info="stuBasicInfo"
                :template-info="templateInfo"
            ></stu-info-table>
        </div>
        <div v-else-if="templateInfo.updateType == '2'">
            <form-main-title
                style="margin:0 auto;"
                :title="templateInfo.archivesName"
            ></form-main-title>
            <stu-info-form
                :key="templateInfo.id"
                :mode="mode"
                :school-id="schoolId"
                :stu-basic-info="stuBasicInfo"
                :template-info="templateInfo"
            ></stu-info-form>
        </div>
    </div>
</template>

<script>
import StuInfoForm from "./Form.vue";
import StuInfoTable from "./Table.vue";
import FormMainTitle from "../../Sub/FormMainTitle/index.vue";
export default {
    name: "StuInfoFillMain",
    components: {
        FormMainTitle,
        StuInfoForm,
        StuInfoTable,
    },
    props: ["mode", "schoolId", "stuBasicInfo", "templateInfo"],
};
</script>

<style lang="scss" scoped>
.stu-info-fill-main {
    padding: 0 0 20px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
}
</style>
